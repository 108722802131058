<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="" elevation="" max-width="">
          <v-card-text>
            <p class=""></p>
            <div class="text--primary">
              Total de prospectos
              {{ tbl_total }}
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- /** tabla y parametros */ -->
    <v-row>
      <v-col cols="9">
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="tbl_items"
              :items-per-page="5"
              class="elevation-3"
              disable-pagination
              :options.sync="options"
              :hide-default-footer="true"
              :item-key="tbl_items.id"
            >
              <!-- fecha en tabla -->
              <template v-slot:[`item.supervisora.nombre_completo`]="{ item }">
                <span
                  style="white-space: nowrap"
                  :title="`Usuario ${item.supervisora.nombre_completo}`"
                  ><v-icon small>mdi-cellphone</v-icon
                  >{{ item.supervisora.alias }}</span
                >
              </template>

              <template v-slot:[`item.fecha`]="{ item }">
                {{ getDate(item.fecha) }}
              </template>
              <template v-slot:[`item.fecha_fin`]="{ item }">
                {{ getDate(item.fecha_fin) }}
              </template>
              <!-- fecha en tabla -->
              <template v-slot:[`item.ver`]="{ item }">
                <v-btn
                  small
                  elevation="1"
                  color="primary"
                  @click="
                    (dialog = !dialog),
                      (dialogItem = item),
                      (prop_center = { lat: item.lat, lng: item.lng, zoom: 17 })
                  "
                  >ver</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
          <v-col>
            <v-pagination
              class="float-right"
              v-model="page"
              :length="totalPages"
              total-visible="7"
              next-icon="mdi-menu-right"
              prev-icon="mdi-menu-left"
              @input="handlePageChange"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3">
        <v-card class="" elevation="" max-width="">
          <v-card-title> Parámetros de búsqueda </v-card-title>
          <v-card-text>
            <!-- SEARCH || mess -->
            <v-dialog
              ref="mesDialog"
              v-model="modalMes"
              :return-value.sync="dialogVarSelectedMes"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedMes"
                  label="Mes:"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="dialogVarSelectedMes"
                scrollable
                type="month"
                show-adjacent-months
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalMes = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.mesDialog.save(dialogVarSelectedMes)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- SEARCH || mess -->

            <!-- /*** Dashboard select - dias */ -->
            <v-dialog
              ref="diaDialog"
              v-model="modalFechas"
              :return-value.sync="dialogVarSelectedDia"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDias"
                  label="Dias:"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :selected-items-text="'$vuetify.datePicker.itemsSelected'"
                locale="es"
                v-model="dialogVarSelectedDia"
                :range="true"
                scrollable
                :min="dialogVarMinDia"
                :max="dialogVarMaxDia"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFechas = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.diaDialog.save(dialogVarSelectedDia)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <!-- /*** Dashboard select - dias */ -->
            <!-- DIVISION -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchDivision"
              :items="itemsDivisiones"
              :loading="loadingDivisiones"
              :search-input.sync="searchSyncDivisiones"
              color="primary"
              :hide-selected="false"
              item-text="division"
              item-value="division"
              label="Divisiones"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            >
            </v-autocomplete>
            <!-- DIVISION -->
            <!-- REGION -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchRegion"
              :items="itemsRegiones"
              :loading="loadingRegiones"
              :search-input.sync="searchSyncRegiones"
              color="primary"
              :hide-selected="false"
              item-text="region"
              item-value="region"
              label="Regiones"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            ></v-autocomplete>
            <!-- REGION -->
            <!-- SECTOR -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchSector"
              :items="itemsSectores"
              :loading="loadingSectores"
              :search-input.sync="searchSyncSectores"
              color="primary"
              :hide-selected="false"
              item-text="codigo"
              item-value="codigo"
              label="Sectores"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            >
            </v-autocomplete>
            <!-- SECTOR -->
            <!-- AGENTE DE COMERCIO -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchAgenteDeComercio"
              :items="$store.state.globalAgentesDeComercio"
              :loading="loadingAgenteDeComercio"
              color="primary"
              label="Agente de comercio"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
            <!-- AGENTE DE COMERCIO -->

            <!-- SUPERVISORA -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="flag_no_data_supervisoras"
              clearable
              @click:clear="flag_no_data_supervisoras = 'Buscar supervisora'"
              v-model="searchSupervisora"
              :items="itemsSupervisoras"
              :loading="loadingSupervisoras"
              :search-input.sync="searchSyncSupervisoras"
              color="primary"
              item-text="nombre_completo"
              item-value="id"
              label="Usuario(s)"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
              return-object
            >
            </v-autocomplete>
            <!-- SUPERVISORA -->
            <!-- ID DE REGISTRO -->
            <v-autocomplete
              cache-items
              chips
              multiple
              deletable-chips
              :no-data-text="'Sin registros disponibles'"
              clearable
              v-model="searchIds"
              :items="itemsIds"
              :loading="loadingIds"
              :search-input.sync="searchSyncIds"
              color="primary"
              :hide-selected="false"
              item-text="id"
              item-value="id"
              label="IDs de registros"
              placeholder="búscar..."
              prepend-icon="mdi-database-search"
            ></v-autocomplete>
            <!-- ID DE REGISTRO -->
            <!-- PROSPECTOS -->
            <v-text-field
              clearable
              v-model="searchProspecto"
              title="Búsca por nombre, dirección o teléfono de prospecto"
              label="Prospecto"
              prepend-icon="mdi-account"
              v-on:keyup.enter="
                fnGetTblProspectos(), (flag_parametros_aplicados = true)
              "
            ></v-text-field>
            <!-- PROSPECTOS -->

            <!-- APLICAR PARAMETROS -->
            <v-btn
              id="Buscar parametros"
              @click="
                (page = 1),
                  fnGetTblProspectos(),
                  (flag_parametros_aplicados = true)
              "
              small
              :class="[
                flag_parametros_aplicados
                  ? 'blue-grey white--text float-right'
                  : 'primary white--text float-right',
              ]"
            >
              Aplicar parametros
              <v-icon right dark> mdi-magnify </v-icon>
            </v-btn>
            <!-- APLICAR PARAMETROS -->
          </v-card-text>
          <v-card-actions>
            <v-btn text color=""> </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- /** tabla y parametros */ -->
    <!-- DIALOG INFO - PROSPECTO -->
    <v-dialog v-model="dialog" max-width="850" persistent>
      <v-card v-if="dialogItem.supervisora">
        <v-card-title class="text-h6" :title="`prospecto id: ${dialogItem.id}`">
          Supervisora: {{ dialogItem.supervisora.nombre_completo }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <div class="text--primary">
                Prospecto:
                <strong>{{ dialogItem.nombre }}</strong>
              </div>
              <div class="text--primary">
                Teléfono:
                <strong>{{ dialogItem.telefono }}</strong>
              </div>

              <div class="text--primary">
                Dirección:
                <strong>{{ dialogItem.direccion }}</strong>
              </div>
              <br />
              <v-divider class="grey lighten-2"></v-divider>
              <br />
              <div class="text--primary">
                Fecha:
                <strong>{{ getDate(dialogItem.fecha) }}</strong>
              </div>
              <div class="text--primary">
                Latitud:
                <strong>{{ dialogItem.lat }}</strong>
              </div>
              <div class="text--primary">
                Longitud:
                <strong>{{ dialogItem.lng }}</strong>
              </div>
              <div class="text--primary mt-3">
                Observación:
                <strong v-if="dialogItem.observaciones">{{
                  dialogItem.observaciones
                }}</strong>
                <span class="text--disabled" v-else> sin comentario </span>
              </div>
            </v-col>
            <v-col>
              <v-divider></v-divider>
              <mapa
                :prop_polyline_enable="false"
                :prop_markers="[dialogItem]"
                :prop_center="prop_center"
                :prop_otros_tipos_de_visita="true"
              />
            </v-col>
            <!-- fin mapa -->
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG INFO - PROSPECTO -->
  </v-container>
</template>
<script>
import { mapActions } from 'vuex';
import moment from 'moment';
require('moment/locale/es.js');
import mapa from '../../components/mapa.vue';
export default {
  name: 'inicio_de_labores',
  components: {
    mapa,
  },
  computed: {
    selectedMes() {
      return this.dialogVarSelectedMes
        ? moment(this.dialogVarSelectedMes).format('MMMM-YYYY')
        : moment().format('MMMM-YYYY');
    },
    dialogVarMinDia() {
      if (this.dialogVarSelectedMes) {
        return moment(this.dialogVarSelectedMes)
          .startOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().startOf('month').format('YYYY-MM-DD');
      }
    },
    dialogVarMaxDia() {
      if (this.dialogVarSelectedMes) {
        return moment(this.dialogVarSelectedMes)
          .endOf('month')
          .format('YYYY-MM-DD');
      } else {
        return moment().format('YYYY-MM-DD');
      }
    },
    superUserValidation() {
      if (this.$store.state.auth.payload.usuario.sesion_rol == 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      flag_no_data_supervisoras: 'Buscar supervisora',
      flag_no_data_prospecto: 'Buscar prospecto',
      //
      flag_parametros_aplicados: true,
      // vacriables de parametros
      //parametros de fechas (modals)
      dialogVarSelectedMes: null,
      dialogVarSelectedDia: null,
      modalMes: false,
      modalFechas: false,
      //
      //supervisoras auntocomplete vars
      itemsSupervisoras: [],
      loadingSupervisoras: false,
      searchSyncSupervisoras: '',
      //itemsAgentesDeComercio: ,
      loadingAgenteDeComercio: false,
      searchAgenteDeComercio: '',
      //consejeras auntocomplete vars
      itemsProspectos: [],
      loadingProspectos: false,
      searchSyncProspectos: '',
      //sectores auntocomplete vars
      itemsSectores: [],
      loadingSectores: false,
      searchSyncSectores: '',
      //divisiones auntocomplete vars
      itemsDivisiones: [],
      loadingDivisiones: false,
      searchSyncDivisiones: '',
      //regiones auntocomplete vars
      itemsRegiones: [],
      loadingRegiones: false,
      searchSyncRegiones: '',
      //search variables
      searchSupervisora: '',
      searchProspecto: '',
      searchSector: '',
      searchDivision: '',
      searchRegion: '',

      //
      //

      searchIds: [],
      loadingIds: false,
      itemsIds: [],
      searchSyncIds: '',

      //
      selectedDias: [
        moment().startOf('month').format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY'),
      ],
      //
      dialogItem: {},
      dialog: false,
      //pagination
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 5,
      pageSizes: [5, 15, 50],
      //tbl
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Supervisora', value: 'supervisora.nombre_completo' },

        { text: 'Division', value: 'division' },
        { text: 'Región', value: 'region' },
        { text: 'Agente de comercio', value: 'tipo' },

        { text: 'Prospecto', value: 'nombre' },
        { text: 'Prosp. Dirección', value: 'direccion' },
        { text: 'Prosp. teléfono', value: 'telefono' },
        { text: 'fecha', value: 'fecha' },
        { text: 'ver', value: 'ver' },
      ],
      tbl_items: [],
      tbl_total: 0,
      tbl_db_search: '',
    };
  },
  watch: {
    options: {
      handler() {
        console.log('handler actived');
        this.fnGetTblProspectos();
      },
      deep: true,
    },
    searchSyncSupervisoras(newValue) {
      this.flag_parametros_aplicados = false;
      if (newValue && newValue != '') {
        if (this.searchSupervisora == null) {
          this.loadingSupervisoras = true;
          //levanta la funcion: fnGetAutocompleteSupervisoras
          this.fnGetAutocompleteSupervisoras();
        } else {
          let currentSupervisora = this.searchSupervisora.nombre_completo;
          if (newValue != currentSupervisora) {
            this.loadingSupervisoras = true;
            //levanta la funcion: fnGetAutocompleteSupervisoras
            this.fnGetAutocompleteSupervisoras();
          }
        }
      }
    },
    searchSyncProspectos(newValue) {
      this.flag_parametros_aplicados = false;
      if (newValue && newValue != '') {
        this.loadingProspectos = true;
        //levanta la funcion: fnGetAutocompleteConsejeras
        this.fnGetAutocompleteConsejeras();
      }
    },

    dialogVarSelectedMes(newValue) {
      if (newValue) {
        this.selectedDias = [
          moment(newValue).startOf('month').format('DD-MM-YYYY'),
          moment(newValue).endOf('month').format('DD-MM-YYYY'),
        ];
        this.dialogVarSelectedDia = [
          moment(newValue).startOf('month').format('YYYY-MM-DD'),
          moment(newValue).endOf('month').format('YYYY-MM-DD'),
        ];
        this.flag_parametros_aplicados = false;
      }
    },
    dialogVarSelectedDia(newValue) {
      this.selectedDias = [
        moment(newValue[0]).format('DD-MM-YYYY'),
        moment(newValue[1]).format('DD-MM-YYYY'),
      ];
      this.flag_parametros_aplicados = false;
    },
    searchSyncSectores(newValue) {
      if (newValue && newValue != '') {
        this.loadingSectores = true;
        //levanta la funcion: fnGetAutocompleteSectores
        this.fnGetAutocompleteSectores();
      }
    },
    searchSyncDivisiones(newValue) {
      if (newValue && newValue != '') {
        this.loadingDivisiones = true;
        //levanta la funcion: fnGetAutocompleteDivisiones
        this.fnGetAutocompleteDivisiones();
      }
    },
    searchSyncRegiones(newValue) {
      if (newValue && newValue != '') {
        this.loadingRegiones = true;
        //levanta la funcion: fnGetAutocompleteRegiones
        this.fnGetAutocompleteRegiones();
      }
    },
    searchSyncIds(newValue) {
      if (newValue && newValue != '') {
        this.loadingIds = true;
        this.fnGetAutocompleteIds();
      }
    },
  },
  methods: {
    ...mapActions('Prospectos', {
      getProspecto: 'get',
      findProspectos: 'find',
      crearProspecto: 'create',
    }),
    ...mapActions('Usuarios', {
      findUsuarios: 'find',
    }),

    getParams() {
      /**
       * configuración de parametros
       */
      const params = {};
      //fechas para feathers

      ///
      //params['id_usuario'] = { $nin: [1] };

      let split1 = this.selectedDias[0].split('-');
      // prettier-ignore
      let firstDay = new Date(split1[2],split1[1] - 1,split1[0] - 1,23, 59, 59);

      let split2 = this.selectedDias[1].split('-');
      let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 23, 59, 59);

      //fechas para feathers
      if (this.searchIds.length == []) {
        params['fecha'] = {
          $gte: firstDay,
          $lte: lastDay,
        };
      }
      params['region'] = { $ne: '' };
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;
      params['$sort'] = { fecha: 0 };

      //
      if (this.searchAgenteDeComercio && this.searchAgenteDeComercio != '') {
        params['tipo'] = { $in: this.searchAgenteDeComercio };
      }
      //
      if (this.searchDivision && this.searchDivision != '') {
        params['division'] = { $in: this.searchDivision };
      }
      if (this.searchRegion && this.searchRegion != '') {
        params['region'] = { $in: this.searchRegion };
      }
      if (this.searchSector && this.searchSector != '') {
        params['sector'] = { $in: this.searchSector };
      }
      // else if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
      //   params['sector'] = {
      //     $in: this.$store.state.auth.payload.codigos_supervisoras,
      //   };
      // }
      //
      /** parametrización de supervisoras asignadas a usuario administrativo */
      if (this.searchSupervisora && this.searchSupervisora != '') {
        let arrayOfIds = this.searchSupervisora.map((a) => a.id);
        params['id_usuario'] = { $in: arrayOfIds };
      }

      if (this.searchProspecto && this.searchProspecto != '') {
        params['$or'] = [
          { nombre: { $like: '%' + this.searchProspecto + '%' } },
          { telefono: { $like: '%' + this.searchProspecto + '%' } },
          { direccion: { $like: '%' + this.searchProspecto + '%' } },
        ];
      }

      //
      if (this.searchIds.length >= 1) {
        params['id'] = { $in: this.searchIds };
      }

      /**
       *
       * retorno de parametros
       */

      return params;
    },
    getParamasAutoCompleteConsejeras() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteConsejeras
       */
      const params = {};

      let split1 = this.selectedDias[0].split('-');
      // prettier-ignore
      let firstDay = new Date(split1[2],split1[1] - 1,split1[0] - 1,23, 59, 59);

      let split2 = this.selectedDias[1].split('-');
      let lastDay = new Date(split2[2], split2[1] - 1, split2[0], 23, 59, 59);

      //fechas para feathers
      params['fecha'] = {
        $gte: firstDay,
        $lte: lastDay,
      };

      if (this.searchSyncProspectos != '') {
        // prettier-ignore
        params['$or'] = [
          { direccion: { $like: '%' + this.searchSyncProspectos + '%' } },
          { nombre: { $like: '%' + this.searchSyncProspectos + '%' } },
          { telefono: { $like: '%' + this.searchSyncProspectos + '%' } },
        ];
      }

      if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
        params['codigo_supervisora'] = {
          $in: this.$store.state.auth.payload.codigos_supervisoras,
        };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    handlePageChange(value) {
      this.page = value;
      this.fnGetTblProspectos();
    },

    fnGetTblProspectos() {
      this.findProspectos({ query: this.getParams() }).then((result) => {
        result.data.forEach((prospecto) => {
          prospecto.motivo_de_visita_id = 'c';
        });
        this.tbl_items = result.data;
        this.tbl_total = result.total;
        this.totalPages = Math.ceil(
          parseInt(result.total) / parseInt(this.pageSize)
        );
      });
    },
    fnGetVisitaImagen(dir) {
      return process.env.VUE_APP_FEATHERS_SERVER + dir;
    },
    getDate(date) {
      return date ? moment(date).utc().format('DD MMM  HH:mm a') : '';
    },
    /*** funciones de parametros */
    fnGetAutocompleteSupervisoras() {
      //
      console.log('fnGetAutocompleteSupervisoras');
      this.findUsuarios({
        query: this.getParamasAutoCompleteSupervisoras(),
      }).then((result) => {
        if (result.data.length == 0) {
          this.flag_no_data_supervisoras = 'Sin registros disponibles';
        } else {
          this.flag_no_data_supervisoras = 'Buscar supervisora';
        }
        result.data.forEach((supervisora) => {
          supervisora.nombre_completo =
            supervisora.alias +
            ' - ' +
            supervisora.tipo +
            ' - ' +
            supervisora.division +
            ' - ' +
            supervisora.region;
        });
        this.loadingSupervisoras = false;
        this.itemsSupervisoras = result.data;
      });
      //
    },
    fnGetAutocompleteConsejeras() {
      //
      console.log('methods - fnGetAutocompleteConsejeras');
      this.findProspectos({
        query: this.getParamasAutoCompleteConsejeras(),
      }).then((result) => {
        if (result.data.length == 0) {
          this.flag_no_data_prospecto = 'Sin registros disponibles';
        } else {
          this.flag_no_data_prospecto = 'Buscar prospecto';
        }
        console.log(result.data);
        this.loadingProspectos = false;
        this.itemsProspectos = result.data;
      });
      //
    },

    getParamasAutoCompleteSupervisoras() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteSupervisoras
       */
      const params = {};
      params['$select'] = [
        'id',
        'nombre_completo',
        'codigo',
        'alias',
        'tipo',
        'division',
        'region',
      ];
      params['region'] = { $ne: '' };
      if (this.searchSyncSupervisoras != '') {
        params['$or'] = [
          {
            codigo: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
          {
            nombre_completo: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
          {
            alias: { $like: '%' + this.searchSyncSupervisoras + '%' },
          },
        ];
      }

      if (this.$store.state.auth.payload.usuario.sesion_rol != 0) {
        params['codigo'] = {
          $in: this.$store.state.auth.payload.codigos_supervisoras,
        };
      }
      /**
       *
       * retorno de parametros
       */
      return params;
    },
    //
    //
    fnGetAutocompleteSectores() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteSectores(),
      }).then((result) => {
        this.loadingSectores = false;
        this.itemsSectores = result.data;

        console.log(result.data);
      });
      //
    },
    fnGetAutocompleteDivisiones() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteDivisiones(),
      }).then((result) => {
        this.loadingDivisiones = false;
        this.itemsDivisiones = result.data;
      });
      //
    },
    fnGetAutocompleteRegiones() {
      //
      this.findUsuarios({
        query: this.getParamasAutocompleteRegiones(),
      }).then((result) => {
        console.log(result.data);
        this.loadingRegiones = false;
        this.itemsRegiones = result.data;
      });
      //
    },
    //
    getParamasAutocompleteSectores() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteSectores
       */
      const params = {};
      params['sesion_rol'] = 2;
      params['$select'] = ['codigo'];
      params['$limit'] = 3;
      params['$sort'] = { codigo: 1 };

      if (this.searchSyncSectores != '') {
        params['codigo'] = { $like: '%' + this.searchSyncSectores + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteDivisiones() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteDivisiones
       */
      const params = {};
      params['$select'] = ['division'];
      params['$limit'] = 3;
      params['$sort'] = { division: 0 };

      if (this.searchSyncDivisiones != '') {
        params['division'] = { $like: '%' + this.searchSyncDivisiones + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    getParamasAutocompleteRegiones() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteRegiones
       */
      const params = {};
      params['$select'] = ['region'];
      params['$limit'] = 3;
      params['$sort'] = { region: 1 };

      if (this.searchSyncRegiones != '') {
        params['region'] = { $like: this.searchSyncRegiones + '%' };
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    fnGetAutocompleteIds() {
      //
      this.findProspectos({
        query: this.getParamasAutocompleteIds(),
      }).then((result) => {
        console.log(result.data);
        this.loadingIds = false;
        this.itemsIds = result.data;
      });
      //
    },
    getParamasAutocompleteIds() {
      /**
       * configuración de parametros
       * envía informacion a los parametros de la funcion: fnGetAutocompleteRegiones
       */
      const params = {};

      console.log(' le siynchos ', this.searchSyncIds);

      if (this.searchSyncIds != '') {
        params['id'] = this.searchSyncIds;
      }

      /**
       *
       * retorno de parametros
       */
      return params;
    },
    //
  },
  mounted() {
    this.fnGetTblProspectos();
    this.flag_parametros_aplicados = false;
  },
};
</script>
